<template>
   <section class="content-container">
      <SectionTabs :tabs="tab_component"/>
      <router-view/>
   </section>
</template>

<script>
   import SectionTabs from    "@/components/Section/SectionTabs.vue";
   import { mapActions } from "vuex";
   export default {
      components: { 
         SectionTabs, 
      },
      data() {
         return {
            title: "Muro",
            tab_component: [
               {
                  name: "Inicio",
                  route: "wall-list",
               },
               {
                  name: "Agregar",
                  route: "post-add",
               },
               {
                  name: "Perfiles",
                  route: "wall-perfiles",
               },
               
            ],
            id_empresa: this.$ls.get("user").empresa[0].id_empresa,
            id_usuario: this.$ls.get("user").id_usuario
         };
      },
      async created(){
         await this.getPostData();
      },
      methods: {
         ...mapActions("wallModule", ["getPostPaginasPostCompleto"]),
         goTo(route) {
            var current_route = this.$route.name;
            if (route == current_route) {
               return;
            } else {
               this.$router.push({ name: route });
            }
         },
         activeTab(tab) {
            var current_route = this.$route.name;
            if (tab == current_route) {
               return "active";
            }
         },
         async getPostData (){
            this.show_spinner = true;
            var data2_ = {
               id_empresa: this.id_empresa,
               id_usuario: this.id_usuario,
               plataforma: "2",
               fecha_inicio:  "" ,
               fecha_final: "",
               nombre: "",
               limite: "10",
            };
            let pagina = "1";
            await this.getPostPaginasPostCompleto({data2_, pagina}).then(res=>{
               if(res == null){
                  this.$toast.open({
                     message:`Ocurrió un error al obtener la información.`,
                     type:'error',
                     duration:0,
                     position:'top-right'
                  });
               }
            });
            
            //console.log("data_: ", {data2_, pagina});
            this.show_spinner = false;
         }
      },
   };
</script>